.popupbooth-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 10000;
}

.popupbooth-page {
    width: max(70%, 1200px);
    background-color: white;
    margin: auto;
    margin-top: min(6%, 120px);
    position: relative;
    height: 578px;
}

.popupbooth-inner {
    width: 100%;
    height: 100%;
    position: relative;
}

.popupbooth-left {
    width: 58%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
}

.popupbooth-divider {
    width: 1%;
    height: 100%;
    position: absolute;
    left: 58%;
    color: white;
    background-color: white;
}

.popupbooth-video {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.popupbooth-right {
    position: absolute;
    width: 41%;
    height: 100%;
    max-height: 100%;
    left: 59%;
    display: flex;
    flex-direction: column;
}

.popupbooth-card-img {
    position: relative;
    max-width: 100px;
}

.popupbooth-left-title,
.popupbooth-right-title {
    background-color: var(--dark-blue);
    height: 2em;
    color: white;
    line-height: 2em;
    padding-left: 12px;
    position: relative;
    display: flex;
    flex-direction: row;
}

.popupbooth-right-title {
    padding-left: 0px;
}

.popupbooth-right-title-text {
    flex: 1;
    padding-left: 12px;
}

.popupbooth-right-title-divider {
    width: 0px;
    background-color: white;
}

.popupbooth-right-title-close {
    padding: 7px;
    transition: .3s;
    background: none;
    border: none;
}

    .popupbooth-right-title-close:hover {
        cursor: pointer;
        background-color: var(--floor-button-hover);
    }

    .popupbooth-right-title-close > img {
        max-width: 15px;
        position: relative;
        top: -9px;
    }

.popupbooth-left-video {
    position: relative;
    width: 99%;
    height: 450px;
    background-color: black;
    margin: 10px;
    margin-right: 0px;
    margin-bottom: 0px;
}

.popupbooth-left-bottom {
    margin: 10px;
    flex: 1;
    height: 100px;
    margin-right: 0px;
}

.popupbooth-left-bottom-row {
    height: 100%;
    position: relative;
}

.popupbooth-left-bottom-scroll {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: white;
    line-height: 2em;
    display: flex;
    overflow: hidden;
}

.popupbooth-row-img {
    height: 100%;
    width: 17%;
    margin-right: 10px;
    background-color: #eee;
    border: 2px solid black;
    transition: .3s;
    position: relative;
    top: 0px;
}

    .popupbooth-row-img:hover {
        background-color: #ddf;
    }

.popupbooth-row-image {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
}

.popupbooth-row-image > img {
    max-width: 100%;
    max-height: 100%;
}

.popupbooth-row-image:hover {
    opacity: 0.7;
}

.popupbooth-right-scroll {
    flex: 1;
    background-color: #eee;
    overflow: scroll;
    overflow-x: hidden;
    padding: 10px;
    border: 2px solid black;
    margin-top: 10px;
    margin-right: 10px;
}

.popupbooth-right-scroll-content {
    position: relative;
    height: 100%;
}

.popupbooth-right-scroll-content-inner {
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    font-size: 70%;
}

.popupbooth-right-buttons {
    position: relative;
    bottom: 0px;
    height: 86px;
    background-color: white;
    border-bottom-right-radius: 10px;
    width: 100%;
}

.popupbooth-button-left {
    position: relative;
    width: 40%;
    height: 100%;
    margin-right: 20%;
    display: inline-block;
    color: white;
}

.popupbooth-button-right {
    position: relative;
    width: 40%;
    height: 100%;
    display: inline-block;
    color: white;
}

    .popupbooth-button-left:hover,
    .popupbooth-button-right:hover {
        opacity: 0.7;
        transition: opacity 0.2s ease-in-out;
    }

.popupbooth-button {
    width: 100%;
    bottom: 0px;
    text-align: center;
    border-radius: 0px;
    box-shadow: none;
    background-color: var(--dark-blue) !important;
    bottom: 10px;
    line-height: 3em;
    font-size: 90%;
    font-weight: 100;
    cursor: pointer;
    left: 0px;
    letter-spacing: unset !important;
    color: white;
    border: none;
}

.popupbooth-row-img-center,
.popupbooth-left-video-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
}

.popup-card-close {
    position: absolute;
    top: 9px;
    right: 0px;
}

    .popup-card-close:hover {
        background-color: #8888;
    }

.card-close-img {
    max-width: 21px;
}