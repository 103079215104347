.menu-button-container {
    position: relative;
}

.menu-button-inner-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    transform: translateY(-100%);
}

    .menu-button-inner-container button {
        white-space: pre;
    }