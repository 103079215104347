.map-button-container {
    position: relative;
}

.map-button-inner-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    transform: translateY(-100%);
    padding-bottom: 20px;
}

.map-button-inner-wrapper {
    padding: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 5px !important;
    box-shadow: 5px 5px 5px #0005;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in;
    border-radius: 24px;
    background-color: var(--window-bg-gray);
}

    .map-button-inner-wrapper button {
        white-space: pre;
    }