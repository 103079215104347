.ui-container {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 90;
    padding: 20px 25px;
    pointer-events: none;
}

.ui-column {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    pointer-events: none;
}

.ui-column-left {
    align-items: flex-start;
}

.ui-column-right {
    align-items: flex-end;
}