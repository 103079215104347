.pdf-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.pdf-background {
    width: 90%;
    height: 90%;
    padding: 15px;
    background: white;
    border-radius: 5px;
}

.pdf-viewer {
    width: 100%;
    height: 100%;
}