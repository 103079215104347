.sessions-container-main {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.sessions-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: table;
}

.sessions-container-inner {
    vertical-align: middle;
    display: table-cell;
}

.session-page {
    background-color: #0008;
    border-radius: 5px;
    padding: 15px;
    width: 900px; /*Fallback*/
    width: 100vw;
    max-width: 1200px;
    margin: auto;
}

.session-page-title {
    position: relative;
    width: 100%;
    color: black;
    font-size: 2.5em;
    display: flex;
    flex-direction: row;
}

.session-page-title-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: var(--dark-blue);
    padding-left: 20px;
    color: white;
    line-height: .5em;
    flex-wrap: wrap;
    padding: 10px;
}

.session-page-title-day-date {
    display: flex;
    margin-right: 10px;
}

.session-page-title-date {
    font-size: .35em;
    font-weight: 100;
}

.session-page-title-day {
    font-size: .5em;
    font-weight: 100;
    margin-right: 40px;
}

.session-page-title-dow {
    font-size: .25em;
    font-weight: 100;
}

.session-page-title-text {
    font-size: .5em;
    flex-grow: 1;
    text-align: center;
}

.session-card-container {
    width: 100%;
    position: relative;
    vertical-align: top;
}

.session-card {
    width: 200px;
    display: inline-block;
    margin: 20px 12px 15px 12px;
    vertical-align: top;
    flex-shrink: 0;
    background: white;
    outline: 7px solid white;
    outline-offset: -1px;
    cursor: pointer;
}

    .session-card:hover > div {
        opacity: 0.7;
    }

    .session-card:first-child {
        margin-left: 7px;
    }

    .session-card:last-child {
        margin-right: 7px;
    }

    .session-card.selected {
        background: var(--light-blue-4);
        outline-color: var(--dark-blue);
    }

    .session-card-images {
        overflow-x: auto;
        display: flex;
    }

.session-card-time {
    background: var(--dark-blue);
    color: white;
    display: flex;
    padding: 4px 8px;
}

    .session-card-time > div:nth-child(1) {

    }

    .session-card-time > div:nth-child(2) {
        margin-left: auto;
    }

    .session-card-title {
        font-size: 1.5em;
    }

.session-card-close {
    width: 40px;
    height: 42px;
    font-size: 1em;
    color: white;
    background-color: var(--dark-blue);
    cursor: pointer;
    position: relative;
}

    .session-card-close:hover {
        background-color: var(--floor-button-hover);
    }

.session-card-close-x {
    pointer-events: none;
    font-size: .8em;
    position: absolute;
    width: 50%;
    height: 50%;
    line-height: 0.4em;
    left: 25%;
    top: 25%;
}

    .session-card-close-x > img {
        max-width: 100%;
        max-height: 100%;
    }

.session-card-disabled {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: #9999;
}

.session-card-active {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 1em rgb(44 179 209 / 0.95);
    border-radius: 5px;
    z-index: -1;
    left: 0px;
    top: 0px;
}

.session-card-image {
    position: relative;
    width: 100%;
}

.session-card-img {
    position: relative;
    width: 100%;
}

    .session-card-desc {
        position: relative;
        width: 100%;
        text-align: center;
        padding: .5em;
        vertical-align: middle;
        min-height: 5.1em;
        max-height: 5em;
        display: table;
    }

.session-card-desc-text {
    display: table-cell;
    vertical-align: middle;
    font-size: .9em;
}

.session-card-info {
    display: flex;
    width: 100%;
    background-color: white;
    margin-top: 10px;
    z-index: 10;
}

.session-card-info-img {
    height: 230px;
    width: 230px;
    max-width: 30vw;
    max-height: 30vw;
    flex-grow: 0;
    flex-shrink: 0;
}

.session-card-info-img-img {
    width: 100%;
    object-fit: contain;
    object-position: center;
}

session-card-info-data-header {
    display: flex;
    flex-grow: 1;
}

.session-card-info-header {
    padding: 5px;
    background-color: var(--dark-blue);
}

.session-card-info-header-blue {
    display: none;
}

.session-card-info-header-title {
    flex: 1;
    font-size: 1.0em;
    position: relative;
    color: white;
    font-weight: bold;
    line-height: 1.7;
}

.session-card-info-header-dates {
    flex-grow: 1;
}

.session-card-info-header-speakers {
}

.session-card-info-data {
    display: flex;
    font-size: .9em;
    padding: 10px;
}

.session-card-info-data-header-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    max-height: 27vw;
}

.session-card-info-data-buttons {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    flex-direction: column;
    width: 200px;
    height: 100%;
    padding-left: 10px;
}

.session-card-info-data-text {
}

.session-card-info-data-buttons-bookmark {
    width: 100%;
    padding: 5px;
    background-color: var(--session-baby-blue);
    color: white;
    text-align: center;
    font-size: .9em;
}

.session-card-info-data-button {
    cursor: pointer;
}

.session-card-info-data-button-divider {
    display: none;
    min-height: 20px;
    width: 100%;
}

.session-card-info-data-buttons-nowplaying {
    display: none;
}

.session-card-info-data-buttons-watch {
    margin-top: 12px;
    background-color: #8888;
    position: relative;
    width: 100%;
    padding: 5px;
    color: white;
    text-align: center;
    font-size: .9em;
}

.session-card-play {
    display: inline-block;
    color: white;
    margin-left: 10px;
}

.session-card-play-triangle {
}

.session-disabled {
    color: white !important;
}

.session-card-divider {
    position: absolute;
    width: 11%;
    height: 100%;
    left: -11%;
    top: 0px;
    z-index: 4000;
}

@media only screen and (max-width: 769px) {
    .session-card-info-img {
        display: none;
    }

    .session-card-info-data-header-container {
        max-height: 30vh;
    }

    .session-card-info-data {
        flex-direction: column;
    }

    .session-card-info-data-buttons {
        flex-direction: row;
        height: initial;
        width: 100%;
        margin-top: 12px;
        padding-left: 0;
    }

    .session-card-info-data-buttons-bookmark {
        margin-right: 7px;
    }

    .session-card-info-data-buttons-watch {
        margin-left: 7px;
        margin-top: 0;
    }
}