.chime-top-bar {
    position: relative;
    height: 3em;
    width: 100%;
    background-color: #1E222C;
    color: #eee;
    z-index: 5000;
}


.chime-top-contact {
    position: relative;
    padding-left: 2em;
    padding-right: 2em;
    display: inline-block;
    margin-right: 5%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    
    top: 1em;
}
.chime-top-meeting {
    font-size: 90%;
    display: inline-block;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    position: relative;
    top: 1em;
}

.chime-left-mini
{
    width: max(300px, 20vw);
    height: max(200px, 15vw);
    position: absolute;
    left: 10px;
    bottom: 10px;
    border: 1px solid black;
    background-color: #3333;
}
.chime-top-bar-right
{
    position: relative;
    float: right;
    margin-right: 50px;
    height: 100%;
}

.chime-top-button
{
    padding-left: 2em;
    padding-right: 2em;
    display: inline-block;
    min-height: 4em;
    top: 0px;
    position: relative;
}
.chime-top-button-internal
{
    pointer-events: none;
}
.chime-top-button:hover
{
    cursor: pointer;
}
.chime-top-button-text
{
    display: inline-block;
    position: relative;
    top: 5px ;
    margin-left: 5px;
    font-size: 90%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}
.chime-top-button-cover
{
    position: absolute;
    height: 3em;
    top: -10px;
    height: 100px;
    left: 0px;
    width: 100%;
}
.chime-top-button-cover:hover {
    background-color: #fff3;
}
.chime-top-button-cover:active
{
    background-color: #fff4;
}
.chime-main-container
{
    width: 100%;
    position: relative;
    margin: auto;
    z-index: 5000;
}
.chime-main
{
    background-color: #ddd;
    position: relative;
    border: 2px solid black;
    display: flex;
    flex-direction: row;
    
}
.chime-left-screen {
    flex: auto;
    border: 1px solid black;
    margin: 10px;
    background: #888;
    --background: repeating-linear-gradient( 45deg, #606dbc, #606dbc 10px, #465298 10px, #465298 20px );
}
.chime-share-video
{
    position: relative;
    max-width: 100%;
    max-height: 90%;   
    margin: auto;
    
}
.chime-local-video 
{

object-fit: cover;
max-height: max(200px, 20vh);
}
.chime-group-local-video {
    object-fit: cover;
    position: relative;
    width: 100%;
    height: 100%;
}
.chime-other-video
{
    object-fit: cover;
    max-height: max(200px, 20vh);
    position: absolute;
    bottom: 0px;
    left: 0px;
}
.chime-right-side
{
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: white;
    width: max(300px, 20vw);
    display: flex;
    flex-direction: column;
   height: 100%;
}
.chime-right-side-group
{
    width: max(600px, 40vw);
}
.chime-right-video {
    text-align: center;
    position: relative;
    overflow: hidden;
    background: repeating-linear-gradient( 48deg, #333, #666 30px, #333 40px, #444 30px );
    line-height: 0em;
    padding: 0px;
}
.chime-video-name
{
    position: absolute;
    background-color: #3333;
    width: 100%;
    font-size: 70%;
    color: white;
    font-weight: bold;
    bottom: 1em;
    text-align: center;
    
}
.chime-title-bar
{
    position: relative;
    width: 100%;
    background-color: #176fc0;
    font-size: 80%;
    text-align: center;
    color: #eee;
    padding: .25em;
}
.chime-attendee-title-bar
{
    position:  relative;
    width: 100%;
}
.chime-chat-area
{
    overflow-y: scroll;
    
}
.chime-chat-name
{
    font-weight: bold;
}
.chime-chat-mine
{
    background-color: aliceblue!important;
}
.chime-chat-entry
{
    background-color: white;
    padding: .25em;
    max-width: max(30vw, 300px);
}
.chime-chat-text
{
    position: relative;
    display: inline-block;
    width: 75%;
    background-color: #edeced;
    border-radius: 10px;
    padding: .25em;
    
}
.chime-chat-time
{
    position: relative;
    display: inline-block;
    width: 25%;
    color: #222;
    font-size: 60%;
    margin: auto;
    vertical-align: middle;
    height: 2em;
    text-align: right;
}

.chime-separator
{
    position: relative;
    width: 100%;
    background-color: #222;
    height: 1px;
}


.chime-chat-sendarea
{
    position: relative;
    height: 2.5em;
    width: 100%;
    display: flex;
    flex-direction: row;
    
}
.chime-chat-input
{
    flex: 1;
    display: inline-block;
    height: 2em;
    margin: .25em;
}
.chime-chat-send
{
   
    background-color: white;
    border: 1px solid gray;
    display: inline-block;
    margin-top: .25em;
    margin-right: .25em;
    margin-bottom: .25em;
    height: 2em;
    padding: .25em;
    padding-left: 1em;
    padding-right: 1em;
    transition: .25s;
    border-top-right-radius: .75em;
    border-bottom-right-radius: .75em;
}
.chime-chat-send:hover
{
    transition: .25s;
    background-color: #eee;
    cursor: pointer;
}
.chime-chat-send:active
{
    border: 2px solid black;
    transition: 0s;
}
#chime-chat-input
{
    width: 100%;
    border: 1px solid gray;
    border-bottom-left-radius: .75em;
    border-top-left-radius: .75em;
    padding-left: 1em;
    padding-top: .125em;
    padding-bottom: .125em;
    height: 2em;
}
.chime-mute-icon
{
    position: relative;
    top: 5px;
    max-height: 32px;
}
.chime-video-icon,
.chime-video-off-icon
{
    position: relative;
    max-height: 38px;
    top: 5px;
}

.chime-screen-icon
{
    position: relative;
    max-height: 34px;
    top: 5px;
}
.chime-endcall-icon
{
    position: relative;
    max-height: 25px;
    top: 3px;
}
.chime-attendees-section
{
    padding-top: 10px;
    padding-bottom: 10px;
}
.chime-attendees-title-bar {
    background-color: #eee;
    border-bottom: 2px solid #bbb;
    padding: 3px;
    padding-left: 10px;
    font-size: 90%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bold;
    color: #333;
}
.chime-attendee-data
{
    padding-left: 10px;
    font-weight: bold;
    font-size: 90%;
    color: #555;
    height: 33px;

}
.chime-attendee-name
{
    
    display: inline-block;
    padding-left: 3px;
}
.chime-attendee-icons
{
    position: relative;
    float: right;    
    top: -10px;
    margin-right: 20px;
    
    display: inline-block;
}
.chime-attendee-icon {
    width: 20px;
    height: 20px;
    padding: 2px;
    display: inline-block;
    margin: 6px;
    transition: .3s;

}
.chime-attendee-icon:hover
{    
    cursor: pointer;
}

.chime-group-video-panels
{
    position: relative;
    height: 100%;
    line-height: 0;
}

.chime-main-video-panel
{
    background-color: #333;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: inline-block;
    margin: .2%;
    width: 32.9%;
    height: 32.7%;
    margin-bottom: 0%;
    margin-top: 0.3%;
    overflow: hidden;
}
.chime-remote-video
{
    
    position: relative;
 
    width: 100%;
    height: 100%;
    transition: .3s;
}
.chime-group-video-user-icons
{
    position: absolute;
    right: 0px;
    top: 10px;
    padding: 0px 2px 4px 4px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #eee8;
}
.chime-group-video-user-icon
{
    width: 30px;
    height: 30px;
    color: white;
    background-color: #555;
    border-radius: 3px;
    margin-top: 5px;
    text-align: center;
    font-size: 80%;
    word-break: keep-all;
    line-height: 2em;
    cursor: pointer;
    transition: .3s;
}
.chime-group-video-user-icon:hover
{
    background-color: #999;
}

.chime-group-video-full {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 90%;
    transition: 1s;
    z-index: 6000;
}
.chime-group-video-bottom {
    background-color: #eee;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: inline-block;
    margin: .2%;
    top: 90.5%;
    height: 9%;
    width: 12%;
    margin: 0;
    margin-left: .44%;
    transition: 1s;
}
.chime-group-video-bottom>.chime-group-video-user-icons
{
    display: none;
}
.chime-user-initials-container
{
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0px;
}
.chime-user-initials-inner
{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    margin: auto;
}
.chime-user-initials {
    position: relative;
    width: 130px;
    height: 130px;
    border-radius: 500px;
    line-height: 2.8em;
    color: #ddd;
    font-size: 300%;
}
.chime-group-video-bottom > .chime-user-initials {
    width: 40px;
    height: 40px;
    
}
