.waypoint-icon {
    position: relative;
    width: 100px;
    height: 100px;
    transform-style: preserve-3d;
    perspective: 1000px;
}

.waypoint-icon-click {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: none;
    border: none;
}

    .waypoint-icon-click:hover {
        cursor: pointer;
    }

        .waypoint-icon-click:hover ~ .waypoint-icon-shadow {
            filter: invert(0.35);
            opacity: 0.6;
        }

.waypoint-icon-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(./waypoint_icon_black.svg);
    background-size: 100% 100%;
    transform: scale(1);
    opacity: 0.5;
    pointer-events: none;
}

.waypoint-icon-particle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(./waypoint_icon_white.svg);
    background-size: 100% 100%;
    opacity: 0;
    pointer-events: none;
    animation-name: waypoint-icon;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-timing-function: linear;
}

@keyframes waypoint-icon {
    0% {
        transform: translateZ(0) scale(1);
        opacity: 0;
    }

    50% {
        transform: translateZ(-50px) scale(0.5);
        opacity: 1;
    }

    100% {
        transform: translateZ(-100px) scale(0);
        opacity: 0;
    }
}
