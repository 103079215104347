.floors-menu-container {
    padding: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 5px !important;
    box-shadow: 5px 5px 5px #0005;
    inline-size: fit-content;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in;
    border-radius: 24px;
    background-color: var(--window-bg-gray);
    pointer-events: auto;
    overflow-y: auto;
    margin-bottom: 20px;
}

    .floors-menu-container .inner-menu-button-container {
        width: 100%;
        padding: 0 !important;
    }

    .floors-menu-container button {
        padding: 10px 15px !important;
        font-size: 1.15rem !important;
    }

.floors-menu-container-entry {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 10px !important;
}

.floors-menu-floor-number {
    margin: auto;
    padding-right: 12px;
    color: white;
}