.video-chat-container {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: auto;
    transition: background 0.5s ease-in-out;
}

.video-chat-container-minimized {
    background: transparent;
    pointer-events: none;
}

.video-chat-background {
    position: absolute;
    left: 50%;
    bottom: 50%;
    display: flex;
    width: 90%;
    height: 90%;
    padding: 15px;
    background: white;
    border-radius: 5px;
    transform: translate(-50%, 50%);
    transform-origin: left bottom;
    transition: transform 0.5s ease-in-out, border-radius 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
    pointer-events: auto;
}

.video-chat-container-minimized .video-chat-background {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    transform: translate(-55.5%, 55.5%) scale(0.5);
    border-bottom-left-radius: 0;
}

.videocall-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: lightgray;
    border-radius: 5px;
}

    .videocall-container h3 {
        color: black;
        font-weight: bold;
        opacity: 0.4;
    }

.videocall-controls-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: center;
}

.selfvideo-chat-container {
    width: 20%;
    min-width: 350px;
    padding-left: 15px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
}

.selfvideo-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45%;
    overflow: hidden;
    margin-bottom: 15px;
    background: lightgray;
    border-radius: 5px;
}

    .selfvideo-container video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        pointer-events: none;
        background: black;
    }

    .selfvideo-container h3 {
        color: black;
        font-weight: bold;
        opacity: 0.4;
    }

.chat-container {
    position: relative;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 55%;
    background: #EDF0F6;
    border-radius: 5px;
    padding: 14px;
}

    .chat-container.disabled,
    .chat-container.disabled * {
        pointer-events: none !important;
    }

.chat {
    width: 100%;
    height: 1px;
    flex-grow: 1;
}

.chat-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 15px;
}

    .chat-input-container input {
        width: 100%;
        border: none;
        border-radius: 5px;
        padding: 10px;
        font-size: 0.9rem;
    }

        .chat-input-container input:focus {
            outline: 1px solid lightgray;
        }

.chat-input {
    width: 100%;
}

.chat-send-container {
    padding-left: 10px;
}

.chat-send {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 10px;
    background: MediumSeaGreen;
    border-radius: 14px;
    cursor: pointer;
    user-select: none;
}

    .chat-send:hover {
        opacity: 0.7;
    }

    .chat-send > svg {
        user-select: none;
        width: 100%;
        height: 100%;
    }

.video-chat-disabled-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.video-chat-error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: 501;
}

.video-chat-error-message {
    text-align: center;
    font-size: 20px;
    color: #666;
    background: white;
    padding: 20px;
    border-radius: 5px;
}

    .video-chat-error-message button {
        font-size: 16px;
        border: none;
        border-radius: 5px;
        padding: 6px 12px;
        margin-top: 20px;
        background: CornflowerBlue;
        color: white;
    }

        .video-chat-error-message button:hover {
            opacity: 0.6;
        }

        .video-chat-spinner-container {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            z-index: 2;
        }

.videocall-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
}

.button-call-generic {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(128, 128, 128, 0.75);
    border-radius: 50%;
    margin: 5px 10px;
    width: 45px;
    height: 45px;
    cursor: pointer;
}

    .button-call-generic:hover {
        background: rgb(160, 160, 160, 0.75);
    }

.button-call-generic-red {
    background: #FD5D5C !important;
}

.button-call-end {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FD5D5C;
    border-radius: 20px;
    margin: 5px 10px;
    width: 60px;
    height: 60px;
    cursor: pointer;
}

    .button-call-end:hover {
        background: #FE9494;
    }

.chat-messages-container {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    padding: 0 12px;
}

.chat-message-wrapper {
    width: 100%;
    margin-bottom: 15px;
}

    .chat-message-wrapper:last-child {
        margin-bottom: 0;
    }

.chat-message {
    text-align: left;
    position: relative;
    display: inline-block;
    max-width: 85%;
    padding: 8px 10px;
    font-size: 0.9rem;
    border-radius: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: font-size 0.5s ease-in-out;
}

.video-chat-container-minimized .chat-message {
    font-size: 1.4rem;
}


.chat-message-right {
    color: black;
    background: white;
    border-top-right-radius: 4px;
}

.chat-message-left {
    color: white;
    background: CornflowerBlue;
    border-top-left-radius: 4px;
}

.chat-message-arrow-right {
    position: absolute;
    right: -8px;
    top: 1px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid white;
    transform: scaleY(0.7);
    transform-origin: top;
}

.chat-message-arrow-left {
    position: absolute;
    left: -8px;
    top: 1px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid CornflowerBlue;
    transform: scaleY(0.7);
    transform-origin: top;
}

.button-toggle-selfvideo-chat {
    display: none !important;
    position: absolute;
    bottom: 8px;
    right: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: CornflowerBlue;
    border-radius: 9px;
    margin: 5px 10px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

    .button-toggle-selfvideo-chat:hover {
        filter: brightness(1.2);
    }

.chat-input-container .button-toggle-selfvideo-chat {
    width: 40px;
    height: 40px;
    padding: 10px;
    margin: 0;
    position: initial;
    bottom: initial;
    right: initial;
}

.text-that-needs-wrapping {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

/* Widescreen */
@media only screen and (max-width: 1200px) {
    .video-chat-background {
        flex-direction: column;
    }

    .videocall-container {
        height: 55%;
        margin-bottom: 15px;
    }

    .selfvideo-chat-container {
        width: 100%;
        min-width: initial;
        height: 45%;
        padding-left: 0;
        padding-bottom: 0;
        flex-direction: row;
    }

    .selfvideo-container {
        width: 45%;
        height: 100%;
        margin-bottom: 0;
    }

    .chat-container {
        width: 55%;
        height: 100%;
        margin-left: 15px;
    }
}

/* 4:3 */
@media only screen and (max-width: 768px) {
    .video-chat-container h3 {
        font-size: 1rem;
    }

    .videocall-container {
        height: 60%;
    }

    .selfvideo-chat-container {
        height: 40%;
    }

    .selfvideo-container {
        width: 50%;
    }

    .chat-container {
        width: 50%;
    }
}

.disable-transition {
    transition: none !important;
}

/* Phone */
@media only screen and (max-width: 700px) {

    .selfvideo-chat-container {
        position: relative;
        overflow: hidden;
    }

    .selfvideo-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        transition: transform 0.5s ease-in-out;
        transform: translateX(-101%);
    }

        .selfvideo-container.in-view {
            transform: none;
        }

    .chat-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        margin-left: 0;
        transition: transform 0.5s ease-in-out;
        transform: translateX(101%);
    }

        .chat-container.in-view {
            transform: none;
        }

    .chat-input {
        padding-left: 10px;
    }

    .button-toggle-selfvideo-chat {
        display: flex !important;
    }
}
