.css3d-product-container {
    transform-style: flat !important;
}

.css3d-product-svg {
    width: 100%;
    height: 100%;
    pointer-events: none;
}

    .css3d-product-svg path {
        opacity: 0;
        cursor: pointer;
        pointer-events: auto;
    }

    .css3d-product-svg image {
        opacity: 0;
        transition: opacity 0.4s ease-out;
        pointer-events: none;
    }

    .css3d-product-svg path:hover + image {
        opacity: 1;
    }

.css3d-product-marker {
    position: absolute;
    left: 50%;
    top: 0;
    font-size: 30px;
    color: red;
    transition: transform 0.3s ease-out;
    transform: translate(-50%, -135%);
}

.css3d-product-container:hover .css3d-product-marker {
    transform: translate(-50%, -100%);
}
