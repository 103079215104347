.info-space {
    padding-bottom: 15px;
}

.info-card-header {
    position: relative;
    text-align: center;
    color: white;
    background-color: transparent;
    border-bottom: 2px solid white;
}

.info-button {
    background-color: white;
    color: black;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 6px 6px 6px #0005;
    border: none;
    width: 100%;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

    .info-button:hover {
        background-color: #ccc;
    }

    .info-button:disabled {
        cursor: default !important;
        background-color: #888 !important;
    }

.info-card {
    background-color: #0008;
    border: none;
    width: 400px;
}

.card-info-close {
    position: absolute;
    top: 12px;
    right: 15px;
    display: block;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    opacity: 0.7;
    z-index: 1;
    background: none;
    border: none;
}

.card-info-close:hover {
    opacity: 1;
}

.card-close-img {
    max-width: 21px;
}

.css-text-sign-i-big {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 74px;
    border: 4px solid white;
    border-radius: 50%;
    position: relative;
    background-color: rgb(1,92,171);
    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

.css-text-sign-i-big:hover {
    background-color: rgb(31,122,201);
}

.css-text-sign-i-inner-big {
    font-size: 40px;
    color: white;
    font-weight: bold;
    padding-bottom: 4px;
    user-select: none;
}

.card-header-text {
    user-select: none;
}