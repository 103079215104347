.product-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.product-background {
    width: 65%;
    height: 75%;
    padding: 3.75rem;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.product-inner-container {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 2.5rem 1.25rem;
    background: white;
    border-radius: 5px;
}

.product-inner-container-cell {
    width: 50%;
    height: 100%;
    padding: 0 2rem;
    overflow-y: auto;
}

.product-display {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 10px;
}

.product-display-img {
    position: absolute;
    left: 0;
    right: 0;
    display: none;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

    .product-display-img.selected {
        position: static;
        display: block;
    }

.product-display-colors-container {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px;
}

.product-display-colors-container-color {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid rgba(0, 0, 0, .25);
    margin-top: 8px;
    cursor: pointer;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
}

    .product-display-colors-container-color.selected {
        border: 2px solid rgba(0, 0, 0, 1);
    }

.product-display-colors-container > div:hover {
    opacity: 0.5;
}

.product-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.product-detail {
    background: #ccc;
    width: 100%;
    padding: 3px;
    margin-bottom: 12px;
    text-align: center;
}

.product-btn {
    background: crimson;
    color: white;
    padding: 10px;
    margin-top: 15px;
    border: none;
    border-radius: 20px;
    font-size: 1.4rem;
}

    .product-btn:hover {
        opacity: 0.5;
    }

.product-btn-2 {
    background: #333;
    color: white;
    padding: 10px 50px;
    margin-top: 15px;
    margin-left: auto;
    border: none;
    border-radius: 20px;
    font-size: 1.2rem;
}

.product-btn-2:hover {
    opacity: 0.5;
}

@media only screen and (max-width: 1200px) {
    .product-background {
        width: 90%;
        height: 95%;
        padding: 1rem;
    }
}

@media only screen and (max-width: 1000px) {

    .product-inner-container {
        flex-direction: column;
        padding: 1rem 0.5rem;
    }

    .product-background {
        width: 100%;
        height: 100%;
    }
        .product-inner-container-cell {
            padding: 0.5rem 1rem;
            width: 100%;
            height: 50%;
        }

    .product-btn,
    .product-btn-2 {
        font-size: 1rem;
    }
}
