.menu-button {
    font-size: 1rem;
    border: none;
    box-shadow: 4px 4px 4px #2224;
    border-radius: 5px;
    background-color: #0006;
    transition: .3s ease-out;
    padding: 0.8rem;
    letter-spacing: 3px;
    user-select: none;
    cursor: pointer;
    text-align: center;
    color: white;
}

    .menu-button:hover {
        background-color: #8888;
    }

.inner-menu-button-container {
    padding: 6px 10px;
}

.inner-menu-button {
    background-color: white;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 5px 5px 5px #0004;
    border: none;
    color: #444;
    font-size: 90%;
    transition: background-color .2s ease-in-out;
    cursor: pointer;
    width: 100%;
}

    .inner-menu-button:hover {
        background-color: #aaa;
    }

.ui-button {
    pointer-events: auto;
    min-width: 200px;
}

.ui-button-sound-icon {
    width: 40px;
    height: 32px;
    padding-right: 8px;
}

.popup-close-button {
    border: none;
    background: rgba(0, 0, 0, 0.5);
    padding: 0;
    margin-bottom: 5px;
}

    .popup-close-button:hover {
        background: rgba(128, 128, 128, 0.5);
    }

@media only screen and (max-width: 512px) {
    .ui-button {
        font-size: 0.8rem;
        font-weight: bold;
        padding: 0.6rem;
        min-width: 130px;
    }

    .ui-button-sound-icon {
        width: 28px;
        height: 22px;
        padding-right: 6px;
    }
}